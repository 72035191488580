<ng-container *ngIf="{ user: this.as.user$ | async, isHandset: isHandset$ | async } as obs">
  <mat-sidenav-container class="sidenav-container">

    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="obs.isHandset ? 'dialog' : 'navigation'" [mode]="obs.isHandset ? 'over' : 'side'" [opened]="false">
      <mat-toolbar>Menu</mat-toolbar>
      <ng-container *ngIf="obs.user as user">
        <mat-nav-list>
          <a mat-list-item *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/dash'" (click)="drawer.close()">Dashboard</a>
          <a mat-list-item *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/invitations'" (click)="drawer.close()">Assess</a>
          <a mat-list-item *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/reports'" (click)="drawer.close()">Discover</a>
          <div class="hq-nav" *ngIf="user.hqAccess">
            <hr [ngClass]="{'hide': router.url.includes('/hq')}">
            <a *ngIf="router.url.includes('/hq/') && router.url.length > 7" mat-list-item class="portal-id">{{router.url.substring(4)}}</a>
            <a *ngIf="router.url === '/hq' || router.url === '/hq/new'" mat-list-item routerLink="hq/new">New Portal</a>
          </div>
          <a mat-list-item *ngIf="user.admin" [routerLink]="'admin-search'" (click)="drawer.close()">Admin Search</a>
        </mat-nav-list>
      </ng-container>
    </mat-sidenav>


    <mat-sidenav-content class="sidenav-content">
      <header>
        <!-- ALERT MESSAGES -->

        <app-top-alert-bar></app-top-alert-bar>

        <!-- TOP TOOLBAR-->
        <mat-toolbar class="toolbar">

          <mat-toolbar-row class="container" [ngClass]="{'container-width': router.url !== '/hq'}">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="obs.isHandset">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <img class="logo" src="../../../assets/images/Company Logo Inverse.svg">
            <ng-container *ngIf="obs.user as user">
              <ng-container *ngIf="obs.isHandset === false">

                <ng-container *ngIf="user.hqAccess">
                  <a mat-button *ngIf="user.lastPortalId" routerLink="hq" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" class="top-nav-item">Portals</a>
                </ng-container>

                <a mat-button *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/dash'" routerLinkActive="active-link" class="top-nav-item" id="dashboard">Dashboard</a>
                <a mat-button *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/invitations'" routerLinkActive="active-link" class="top-nav-item" id="invite">Assess</a>
                <a mat-button *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/reports'" routerLinkActive="active-link" class="top-nav-item" id="discover">Discover</a>

              </ng-container>

              <span class="toolbar-spacer"></span>



              <ng-container *ngIf="!environment.production">
                <mat-icon class="dev-icon" matTooltip="This is a test environment. Contact support if you see this.">code</mat-icon>
              </ng-container>

              <div class="profile">
                <a mat-button [matMenuTriggerFor]="userMenu">
                  <mat-icon>perm_identity</mat-icon>
                  <span class="username" id="username" *ngIf="obs.isHandset === false">{{obs.user.name}}</span>
                  <mat-icon *ngIf="obs.isHandset === false" iconPositionEnd>keyboard_arrow_down</mat-icon>
                </a>
              </div>

              <mat-menu #userMenu="matMenu">
                <a mat-menu-item (click)="openProfile()">
                  <mat-icon>perm_identity</mat-icon>My Profile
                </a>
                <a mat-menu-item *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/settings'" routerLinkActive="active-menu">
                  <mat-icon>settings</mat-icon>Settings
                </a>
                <a mat-menu-item *ngIf="user.lastPortalId" [routerLink]="user.lastPortalId + '/managers'" routerLinkActive="active-menu">
                  <mat-icon>group</mat-icon>Managers
                </a>
                <a mat-menu-item *ngIf="reportingAccess$ | async" [routerLink]="user.lastPortalId + '/reporting'" routerLinkActive="active-menu">
                  <mat-icon>insights</mat-icon>Analytics
                </a>
                <ng-container *ngIf="user.portals && user.portals.length > 1">
                  <div class="menu-break"></div>
                  <a mat-menu-item (click)="openChangeSites()">
                    <mat-icon>autorenew</mat-icon>Switch portal
                  </a>
                  <a mat-menu-item *ngIf="user.admin" [routerLink]="'admin-search'" routerLinkActive="active-menu">
                    <mat-icon>person_search</mat-icon>Admin Search
                  </a>
                </ng-container>
                <ng-container *ngIf="user.hqAccess">
                  <div class="menu-break"></div>
                  <a mat-menu-item *ngIf="user.hqAccess" [routerLink]="'hq/email-search'" routerLinkActive="active-menu">
                    <mat-icon>mail</mat-icon>Email Search
                  </a>
                </ng-container>

                <div class="menu-break"></div>
                <a mat-menu-item href="https://app.getbeamer.com/careerscope/en?category=quick+guides" target="_blank">
                  <mat-icon>help_outline</mat-icon>Help
                </a>
                <div class="menu-break"></div>
                <!-- <a mat-menu-item (click)="exportEvalueeDetails()">Export Evaluee Details</a> -->
                <a mat-menu-item routerLink="login" (click)="this.as.signOut()">
                  <mat-icon>logout</mat-icon>Logout
                </a>
              </mat-menu>

            </ng-container>
          </mat-toolbar-row>
        </mat-toolbar>

        <!-- SEARCH TOOLBAR-->
        <mat-toolbar *ngIf="!router.url.includes('/hq') && obs.user" class="toolbar search-bar">
          <mat-toolbar-row class="container container-width">

            <app-evaluee-search [portalId]="portalId" class="search"></app-evaluee-search>
            <div class="portal-name">
              <span class="portal-label">Portal</span><br>
              <span class="last-portal-name">{{obs?.user?.lastPortal}}</span>
            </div>

          </mat-toolbar-row>
        </mat-toolbar>
        <mat-toolbar *ngIf="router.url === '/hq' && obs.user" class="toolbar search-bar">
          <mat-toolbar-row class="container">

            <career-scope-hq-portal-search class="hq-search"></career-scope-hq-portal-search>

          </mat-toolbar-row>
        </mat-toolbar>

      </header>

      <main class="container" [ngClass]="{'container-width': router.url !== '/hq'}">
        <div class="container-pad">
          <ng-content></ng-content>
        </div>
      </main>

      <footer>
        <mat-toolbar>
          <div class="container footer" [ngClass]="{'container-width': router.url !== '/hq'}">
            <p>&copy; {{currentYear}} CareerScope. All rights reserved</p>
            <div>
              <a mat-button (click)="sendMessage()">Support</a>
              <a mat-button href="assets/pdfs/CareerScope Online Services Agreement 2023.pdf" target="_blank">Terms of Service</a>
              <a mat-button href="https://careerscope.com/privacy-policy/" target="_blank">Privacy policy</a>
            </div>
          </div>
        </mat-toolbar>
      </footer>
    </mat-sidenav-content>

  </mat-sidenav-container>
</ng-container>