import { AdminUser } from '@career-scope/models';


const AccessRoles = ['EditPortal', 'ViewONETLegacy', 'ViewMyPathDefaultSettings', 'ViewDeveloperContent', 'EditAllowUsernameRegistration', 'EditMyProfileHideName'] as const;

export type AccessRole = typeof AccessRoles[number];

export interface AccessRoleProperty {
  role: AccessRole;
  properties: (keyof AdminUser)[];
  operator?: 'AND' | 'OR';
}

export const AccessRoleProperties: AccessRoleProperty[] = [
  {
    role: 'EditPortal',
    properties: ['admin', 'hqAccess'],
    operator: 'OR'
  },
  {
    role: 'ViewONETLegacy',
    properties: ['hqAccess'],
  },
  {
    role: 'ViewMyPathDefaultSettings',
    properties: ['hqAccess'],
  },
  {
    role: 'ViewDeveloperContent',
    properties: ['showDev'],
  },
  {
    role: 'EditAllowUsernameRegistration',
    properties: ['hqAccess'],
  },
  {
    role: 'EditMyProfileHideName',
    properties: ['hqAccess'],
  }
]