import { Injectable } from '@angular/core';
import { AdminUser, Counselor, Evaluee, Manager, ManagerCounts, MotorPerformanceEstimates, Portal, PortalDashboard, PortalSwitcher, Register, SelfRegistrationLink, SelfRegistrationLoginType, Statistics, UserWithSecretDTO } from '@career-scope/models';
import { Subscription, BehaviorSubject, Observable, combineLatest, of, forkJoin, from } from 'rxjs';
import { catchError, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { AdminRegister, Invitation } from '../models/register.model';
import { SupportMessage } from '../models/supportMessage.model';
import { convertTimestampsPipe } from '../pipes/convert-firestore-timestamp.pipe';
import { AuthService } from './auth.service';
import { EvalueeListService } from './evaluee-list.service';
import { NotificationService } from './notification.service';
import { PortalsService } from './portals.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { FirebaseError } from 'firebase/app';
import { NPS_SURVEY_RESULTS, NpsSurvey } from '../models/nps-survey';
import { addDoc, collection, collectionData, CollectionReference, deleteDoc, doc, docData, DocumentReference, Firestore, getCountFromServer, getDocs, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';



@Injectable({
  providedIn: 'root'
})
export class PortalService {
  portal = new BehaviorSubject<Portal | null>(null);
  shellPortalId = new BehaviorSubject<string | null>(null);
  managers = new BehaviorSubject<Manager[] | null>(null);
  combinedManagerCounts = new BehaviorSubject<ManagerCounts | null>(null);
  counselors = new BehaviorSubject<Counselor[] | null>(null);
  registrations = new BehaviorSubject<Register[] | null>(null);
  selfRegistrationLink = new BehaviorSubject<SelfRegistrationLink | null>(null);
  currentUserManager = new BehaviorSubject<Manager | null | undefined>(undefined); // The user logged in's manager record for this portal
  statistics = new BehaviorSubject<Statistics | null>(null);
  portals = new BehaviorSubject<PortalDashboard[] | null>(null); // If portal belongs to multiple portals
  availablePortals = new BehaviorSubject<PortalSwitcher[] | []>([]); // Used for portal switcher(change sites) to show portal name

  portalSubscription?: Subscription;
  portalsSubscription?: Subscription; // Portals by customer number
  managerSubscription?: Subscription;
  evalueeSubscription?: Subscription;
  refreshSubscription?: Subscription;
  registrationSubscription?: Subscription;
  registrationLinkSubscription?: Subscription;
  inviteeSubscription?: Subscription;

  constructor(
    private firestore: Firestore,
    private as: AuthService,
    private pss: PortalsService,
    private els: EvalueeListService,
    private notif: NotificationService,
    private router: Router,
    private functions: Functions
  ) {
    this.as.user$.pipe(
      tap(user => {
        if (user) {
          return;
        }

        this.portalSubscription?.unsubscribe();
        this.managerSubscription?.unsubscribe();
        this.evalueeSubscription?.unsubscribe();
        this.refreshSubscription?.unsubscribe();
        this.registrationSubscription?.unsubscribe();
        this.registrationLinkSubscription?.unsubscribe();
        this.inviteeSubscription?.unsubscribe();

        this.portal.next(null);
        this.shellPortalId.next(null);
        this.managers.next(null);
        this.combinedManagerCounts.next(null);
        this.counselors.next(null);
        this.registrations.next(null);
        this.currentUserManager.next(null);
        this.statistics.next(null);
        this.selfRegistrationLink.next(null);
        this.els.resetEvalueeListService();
      })
    ).subscribe();
  }

  get portalId(): string {
    const portalId = this.portal.value?.id || '';
    if (portalId === '') {
      console.log('ERROR: Portal Id is expected but missing / not loaded');
    }
    return portalId;
  }

  get portalName(): string {
    const portalName = this.portal.value?.name || '';
    if (portalName === '') {
      console.log('ERROR: Portal Name is expected but missing / not loaded');
    }
    return portalName;
  }

  get availablePortalSwitcher() {
    return this.availablePortals.pipe(
      map(portals => portals.filter(p => p.id !== this.portal.value?.id))
    );
  }

  //#region Load Stuff
  // Load Portal, Managers, and Evaluees for a site
  loadPortal(portalId: string, uid: string): void {

    if (!this.portalSubscription || portalId !== this.portalId) {

      if (this.portalSubscription) {
        this.portalSubscription.unsubscribe();
        // Reset all BehaviorSubjects
        this.portal.next(null);
        this.shellPortalId.next(null);
        this.managers.next(null);
        this.counselors.next(null);
        this.registrations.next(null);
        this.currentUserManager.next(null);
        this.statistics.next(null);
        this.selfRegistrationLink.next(null);
        this.els.resetEvalueeListService();
      }

      const portalDocRef = doc(this.firestore, `portals/${portalId}`) as DocumentReference<Portal>;

      this.portalSubscription = docData<Portal>(portalDocRef).pipe(convertTimestampsPipe())
        .subscribe(res => {
          if (res) {
            // If New portal - change last site information and load everything else
            if (res.id !== this.portal.value?.id) {
              console.log('Loading', res.name);
              this.shellPortalId.next(res.id);
              this.as.setLastPortal(res.id, res.name, uid);

              this.loadManagers(portalId, uid);
              this.loadMultiplePortals(res.customerNumber);
              this.loadPortalRegistrationLink(portalId);
              !this.statistics.value?.timeUpdated ? this.statistics.next({...this.statistics.value, timeUpdated: new Date()}) : null 
              this.els.portalId = res.id;
            }

            // TODO: Remove at later time
            if (!res.motorPerformanceEstimates) {
              const defaultMotorEstimates: MotorPerformanceEstimates = {
                motorCoordination: 50,
                manualDexterity: 50,
                fingerDexterity: 50
              };
              this.updatePortal({ motorPerformanceEstimates: defaultMotorEstimates }, res);
            }

            this.portal.next(res);
            this.loadPortalsForSwitcher(portalId);

          } else {
            console.log('Missing data for site:', portalId);
          }
        });

    }
  }

  loadManagers(portalId: string, currentUserId: string) {

    if (this.managerSubscription) {
      this.managerSubscription.unsubscribe();
    }

    const managersRef = collection(this.firestore, `portals/${portalId}/managers`) as CollectionReference<Manager>;

    this.managerSubscription = collectionData(managersRef).pipe(convertTimestampsPipe())
      .subscribe(res => {
        if (res) {
          console.log('Loaded', res.length, 'managers');
          res.sort((a, b) => a.fullName.localeCompare(b.fullName));
          this.managers.next(res);
          const currentManager = res.find(manager => manager.authUserId === currentUserId) || null;

          if (currentManager) {
            this.updateManagerDashboardStatistics(currentManager.id);
          }

          this.currentUserManager.next(currentManager);
          this.counselors.next(res.filter(man => man.counselor && (man.status === 'active' || man.status === 'invited')).map(man => ({ id: man.id, name: man.fullName, email: man.email })));
        } else {
          console.log('No managers exist for site:', portalId);
        }
      });

  }

  async updateManagerDashboardStatistics(managerId: string) {
    const managerCompleted = (await getCountFromServer(query(collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>, where('counselor.id', '==', managerId), where('status', '==', 'completed')))).data().count;
    const managerArchived = (await getCountFromServer(query(collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>, where('counselor.id', '==', managerId), where('status', '==', 'archived')))).data().count;
    const managerInProgress = (await getCountFromServer(query(collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>, where('counselor.id', '==', managerId), where('status', '==', 'in progress')))).data().count;
    const managerRegistrations = (await getCountFromServer(query(collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>, where('counselor.id', '==', managerId), where('status', '==', 'new')))).data().count;


    const stats = this.statistics.value || {};
    stats.userCompleted = managerCompleted;
    stats.userInProgress = managerInProgress;
    stats.userArchived = managerArchived;
    stats.userInvitations = managerRegistrations;
    stats.timeUpdated = new Date();
    this.statistics.next(stats);
  }

  async tabulateManagerStatistics(managers: Manager[]) {
    const managersWithCounts = await Promise.all(managers.map(async manager => {
      const inProgressQuery = query(
        collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>,
        where('counselor.id', '==', manager.id),
        where('status', '==', 'in progress')
      );

      const completedQuery = query(
        collection(this.firestore, `portals/${this.portalId}/evaluees`) as CollectionReference<Evaluee>,
        where('counselor.id', '==', manager.id),
        where('status', 'in', ['completed', 'archived'])
      );

      const [inProgressResult, completedResult] = await Promise.all([
        getCountFromServer(inProgressQuery),
        getCountFromServer(completedQuery)
      ]);

      const inProgress = inProgressResult.data().count;
      const completed = completedResult.data().count;

      return { id: manager.id, inProgress, completed };
    }));

    this.combinedManagerCounts.next({ managerCounts: managersWithCounts, timeUpdated: new Date() });
  }

  loadMultiplePortals(customerNumber: string) {
    if (this.portalsSubscription) {
      this.portalsSubscription.unsubscribe();
    }

    const portalsDocRef = doc(this.firestore, `dashboards/${customerNumber}`) as DocumentReference<{ portalDashboard: PortalDashboard[]; }>;

    this.portalsSubscription = docData(portalsDocRef).pipe(convertTimestampsPipe(), take(1)).subscribe(
      res => this.portals.next(res?.portalDashboard || null)
    );
  }

  loadPortalsForSwitcher(portalId: string) {
    const portals = this.availablePortals.value;

    // return portals if portalId is already in the list
    if (portals.find(p => p.id === portalId)) {
      return this.availablePortals;
    }

    // Get user
    return this.as.user$.pipe(
      take(1),
      switchMap(user => {
        // Return empty array
        if (!user || !user.portals || user.portals.length === 0) {
          return of([]);
        }

        if (user.hqAccess) {
          // need to get all portals for that have the same customer number as portalId
          return this.pss.portals.pipe(
            skipWhile(portals => portals.length === 0),
            take(1),
            map(portals => {
              const portal = portals.find(p => p.id === portalId) as PortalDashboard;

              const customerNumber = portal.customerNumber;

              const customerPortals = portals.filter(p => p.customerNumber === customerNumber);

              const managerPortals = user.portals?.map(id => ({
                name: portals.find(p => p.id === id)?.name || 'Unknown',
                id
              })) || [];

              // Create a map to remove duplicates
              const availablePortals: PortalSwitcher[] = [...new Map([...customerPortals, ...managerPortals].map(item => [item.id, item])).values()];
              return availablePortals;
            })
          );
        }

        return forkJoin(
          user.portals.map(id => {
            const portalDocRef = doc(this.firestore, `portals/${id}`) as DocumentReference<Portal>;

            return docData(portalDocRef).pipe(
              take(1),
              map(portal => portal ? { id: portal.id, name: portal.name } : null),
            );
          })
        );
      }),
      // Above could return null, filter out null items
      map(portals => portals.filter((p): p is PortalSwitcher => p !== null)),
      // Set portals to behavior subject
      tap(portals => this.availablePortals.next(portals))
    ).subscribe();
  }

  loadPortalRegistrationLink(portalId: string) {
    if (this.registrationLinkSubscription) {
      this.registrationLinkSubscription.unsubscribe();
    }

    const selfRegistrationDocRef = doc(this.firestore, `selfRegistrationLinks/${portalId}`) as DocumentReference<SelfRegistrationLink>;
    this.registrationLinkSubscription = docData<SelfRegistrationLink>(selfRegistrationDocRef).subscribe(
      res => this.selfRegistrationLink.next(res || null)
    );
  }
  //#endregion

  returnAdministration() {
    // Return an administration to available admins
    // TODO: update log
    const portal = this.portal.value;
    if (portal) {
      portal.availableAdmins++;
      this.pss.updatePortal(portal, { availableAdmins: portal.availableAdmins });
    }
  }

  createUserWithUsernameAndSecret(userWithSecretDTO: UserWithSecretDTO) {
    const callable = httpsCallable(this.functions, 'createUserFromAdmin');

    return from(callable(userWithSecretDTO)).pipe(
      take(1),
      tap(() => this.notif.openSnackBar('Evaluee assessment created!', 'Dismiss',
        {
          duration: 6 * 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        })),
      catchError((err: FirebaseError) => {
        console.error(err);
        this.notif.openDismissibleSnackbar(err.message);
        return of(null);
      })
    );
  }



  async sendManagerInvitation(manager: Manager, email: string) {
    try {
      const user = await this.as.currentUser();

      const invitationData = this.createAdminInvitationEmail(this.portalName, manager, email, user);

      await addDoc(collection(this.firestore, 'invitations'), <Invitation>invitationData);

      this.notif.openDismissibleSnackbar('Email queued for delivery!');
    } catch (error) {
      console.error('Error sending invitation: ', error);
      this.notif.openDismissibleSnackbar('Failed to queue email for delivery');
    }
  }

  createAdminInvitationEmail(portalName: string, manager: Manager, email: string, user: AdminUser | null | undefined): Invitation {
    return {
      to: email,
      template: {
        name: 'admin-invitation-email',
        data: {
          name: manager.fullName,
          invitedBy: user?.name || '-',
          portalName,
          portalUrl: environment.managerInvitationURL,
          id: manager.registrationId || '-'
        }
      }
    };
  }

  async sendSupportEmail(message: SupportMessage) {
    const portal = this.portal.getValue();

    if (portal) {
      const supportEmailData = {
        to: 'support@careerscope.com',
        template: {
          name: 'support-email',
          data: {
            name: message.name,
            phone: message.phone,
            portalId: portal.id,
            crmAccountId: portal.crmAccountId,
            customerNumber: portal.customerNumber,
            portalName: portal.name,
            salesRep: portal.salesRepUserName,
            subject: 'Support email',
            uid: message.uid,
            username: message.email,
            message: message.message,
          },
        },
      };

      try {
        // TODO: add strong typing
        await addDoc(collection(this.firestore, 'supportEmails'), supportEmailData);
        this.notif.openDismissibleSnackbar('Support email queued for delivery!');
      } catch (error) {
        console.error('Error sending support email: ', error);
        this.notif.openDismissibleSnackbar('Failed to queue support email for delivery');
      }
    } else {
      console.error('No portal data available');
      this.notif.openDismissibleSnackbar('No portal data available');
    }
  }

  async addAdminRegistration(registerData: AdminRegister) {
    if (this.portalId) {
      // I believe this check was in place for when we were imported legacy users and allowing them to register with email
      const manager: Manager | undefined = this.managers.value?.find(manager => manager?.email === registerData.email);

      const registerDocId = doc(collection(this.firestore, 'adminRegistrations')).id;
      const managerDocId = manager ? manager.id : doc(collection(this.firestore, `portals/${registerData.portalId}/managers`)).id;

      registerData.portalManagerDocId = managerDocId;

      // Add registration into "global" registry
      await setDoc(doc(this.firestore, `adminRegistrations/${registerDocId}`), registerData);

      const newManager: Manager = this.getManagerFromAdminRegistration(manager, registerData, registerDocId, managerDocId);

      await this.saveManager(newManager);

      this.router.navigateByUrl(`${this.portalId}/managers/${managerDocId}`);
    }
  }

  getManagerFromAdminRegistration(manager: Manager | undefined, registerData: AdminRegister, registerDoc: string, managerDoc: string): Manager {
    if (manager) {
      return {
        ...manager,
        status: 'invited',
        counselor: registerData.counselor,
        admin: registerData.admin,
        canInviteAdmin: registerData.canInviteAdmin || false,
        lastModifiedDate: new Date(Date.now()),
        registrationId: registerDoc,
        fullName: registerData.name,
        portalId: registerData.portalId
      };
    }

    return {
      id: managerDoc,
      fullName: registerData.name,
      email: registerData.email,
      registrationId: registerDoc,
      status: 'invited',
      created: new Date(Date.now()),
      // New Rights system
      counselor: registerData.counselor,
      admin: registerData.admin,
      canInviteAdmin: registerData.canInviteAdmin || false,
      restrictViewEvaluees: registerData.restrictViewEvaluees || false,
      lastModifiedDate: new Date(Date.now()),
      deactivatedDate: null,
      portalId: registerData.portalId
    };
  }

  async addImportRegistration(registerData: AdminRegister): Promise<string> {
    const registerDocRef = doc(collection(this.firestore, 'adminRegistrations'));
    const registerDocId = registerDocRef.id;

    try {
      await setDoc(registerDocRef, registerData);
      return registerDocId;
    } catch (error) {
      console.error('Error adding registration: ', error);
      throw new Error('Failed to add registration');
    }
  }

  async removeAdminRegistration(manager: Manager) {
    if (!this.portalId || !manager.registrationId) {
      return;
    }

    const registrationDocRef = doc(this.firestore, `adminRegistrations/${manager.registrationId}`);
    await deleteDoc(registrationDocRef);

    if (!manager.sqlId) {
      // If Manager was not imported then it is safe to remove them from database
      const managerDocRef = doc(this.firestore, `portals/${this.portalId}/managers/${manager.id}`);
      await deleteDoc(managerDocRef);
    } else {
      // If Manager was imported then revoke invitation but do not delete the manager
      manager.status = 'imported';
      manager.registrationId = null;
      const managerDocRef = doc(this.firestore, `portals/${this.portalId}/managers/${manager.id}`);
      await setDoc(managerDocRef, <Manager>manager);
    }

  }


  updateCrmAccountId(portalId: string, newId: string) {
    const portalDocRef = doc(this.firestore, `portals/${portalId}`);
    return updateDoc(portalDocRef, <Partial<Portal>>{ crmAccountId: newId });
  }

  async updateManager(manager: Manager) {
    if (this.portalId) {
      await this.saveManager(manager);
    }
    if (manager.authUserId) {
      await this.as.updateAdminUser({ uid: manager.authUserId, canInviteAdmin: manager.canInviteAdmin, restrictViewEvaluees: manager.restrictViewEvaluees, admin: manager.admin, name: manager.fullName });
    }
  }

  async updateManagerName(name: string) {

    const manager = this.currentUserManager.value;

    // TODO: Check other portals for the same manager (should store list of portals on authuser)

    if (manager) {
      // Update manager info for this portal
      this.saveManager({ ...manager, fullName: name });

      // Filtering uses manager name to make display easier, could use id instead in future
      // Update individual evaluee records inside this portal
      const evalueeQuery = query(
        collection(this.firestore, `portals/${this.portalId}/evaluees`),
        where('counselor.id', '==', manager.id)
      );
      const evalueeSnapshots = await getDocs(evalueeQuery);
      evalueeSnapshots.forEach(async (evalueeDoc) => {
        const evalueeData = evalueeDoc.data() as Evaluee;
        await updateDoc(evalueeDoc.ref, { counselor: { ...evalueeData.counselor, name }, lastModifiedDate: new Date() });
      });

      // Change any registrations by this manager
      const registerQuery = query(
        collection(this.firestore, 'registrations'),
        where('counselor.id', '==', manager.id)
      );
      const registerSnapshots = await getDocs(registerQuery);
      registerSnapshots.forEach(async (registerDoc) => {
        const registerData = registerDoc.data() as Register;
        await updateDoc(registerDoc.ref, { counselor: { ...registerData.counselor, name } });
      });
    }
  }

  getRegistrationsByPortal(portalId: string): Observable<Register[]> {
    const registrationsCollection = collection(this.firestore, 'registrations') as CollectionReference<Register>;
    const q = query(
      registrationsCollection,
      where('portalId', '==', portalId),
      where('status', '!=', 'registered')
    );
    return collectionData<Register>(q) as Observable<Register[]>;
  }

  async saveManager(manager: Manager) {
    if (!this.portalId) {
      throw new Error('Portal ID is not set');
    }
    const managerDocRef = doc(this.firestore, `portals/${this.portalId}/managers/${manager.id}`);
    await setDoc(managerDocRef, { ...manager, lastModifiedDate: new Date() }, { merge: true });
  }

  updatePortal(portalUpdates: Partial<Portal>, portal?: Portal) {
    const portalToUpdate = portal ? portal : this.portal.value;
    if (portalToUpdate) {
      this.pss.updatePortal(portalToUpdate, portalUpdates);
    }
  }

  createRegistrationLink(loginType: SelfRegistrationLoginType) {
    const portal = this.portal.value;

    if (portal && this.portalId) {
      const linkId = doc(collection(this.firestore, 'selfRegistrationLinks')).id;
      const selfRegistrationLink: SelfRegistrationLink = {
        portalId: portal.id,
        portalName: portal.name,
        linkId,
        loginType
      };

      const linkDocRef = doc(this.firestore, `selfRegistrationLinks/${this.portalId}`);
      setDoc(linkDocRef, <SelfRegistrationLink>selfRegistrationLink);
    }
  }

  updateRegistrationLink(loginType: SelfRegistrationLoginType): void {
    const portal = this.portal.value;

    if (portal && this.portalId) {
      const linkDocRef = doc(this.firestore, `selfRegistrationLinks/${this.portalId}`);
      updateDoc(linkDocRef, <Partial<SelfRegistrationLink>>{ loginType });
    }
  }

  removeRegistrationLink(): void {
    const portal = this.portal.value;

    if (portal && this.portalId) {
      const linkDocRef = doc(this.firestore, `selfRegistrationLinks/${this.portalId}`);
      deleteDoc(linkDocRef);
    }
  }

  checkForExistingAdminEmail(email: string): Observable<boolean> {
    const adminRegistrationsCollection = collection(this.firestore, 'adminRegistrations') as CollectionReference<AdminRegister>;
    const adminUsersCollection = collection(this.firestore, 'adminUsers') as CollectionReference<AdminUser>;

    const adminRegistrationsQuery = query(adminRegistrationsCollection, where('email', '==', email));
    const adminUsersQuery = query(adminUsersCollection, where('email', '==', email));

    const adminRegistrations$ = collectionData<AdminRegister>(adminRegistrationsQuery);
    const adminUsers$ = collectionData<AdminUser>(adminUsersQuery);

    return combineLatest([adminRegistrations$, adminUsers$]).pipe(
      map(([adminRegistrations, adminUsers]) => {
        return !!adminRegistrations.length || !!adminUsers.length;
      })
    );
  };

  checkForExistingManagerEmailOnPortal(email: string): Observable<boolean> {
    if (!this.portalId) {
      throw new Error('Portal ID is not set');
    }

    const managersCollection = collection(this.firestore, `portals/${this.portalId}/managers`) as CollectionReference<Manager>;
    const managersQuery = query(managersCollection, where('email', '==', email));
    const managers$ = collectionData<Manager>(managersQuery);

    return managers$.pipe(
      map(res => {
        return !!res.length;
      })
    );
  }

  deactivateManager(managerId: string): Observable<string> {
    if (!this.portalId) {
      throw new Error('Portal ID is not set');
    }

    const deactivateManagerCallable = httpsCallable<{ portalId: string; managerId: string; }, string>(this.functions, 'deactivateManager');
    return from(deactivateManagerCallable({ portalId: this.portalId, managerId })).pipe(
      take(1),
      map(result => result.data),
      tap(() => this.notif.openDismissibleSnackbar('Manager deactivated')),
      catchError((err: FirebaseError) => {
        this.notif.openDismissibleSnackbar('Error deactivating manager: ' + err.message);
        throw err;
      })
    );
  }

  reactivateManager(managerId: string) {
    if (!this.portalId) {
      throw new Error('Portal ID is not set');
    }

    const reactivateManagerCallable = httpsCallable<{ portalId: string; managerId: string; }>(this.functions, 'reactivateManager');
    return from(reactivateManagerCallable({ portalId: this.portalId, managerId })).pipe(
      take(1),
      map(result => result.data),
      tap(() => this.notif.openDismissibleSnackbar('Manager reactivated')),
      catchError((err: FirebaseError) => {
        this.notif.openDismissibleSnackbar('Error reactivating manager: ' + err.message);
        throw err;
      })
    );
  }

  addNewCategory() {
    const identifiers = this.portal.value?.identifiers;

    if (!identifiers || identifiers.length === 0) {


      const newIdentifier = {
        id: 1,
        name: 'New Category',
        tags: [],
      };

      this.updatePortal({ identifiers: [newIdentifier] });
      return newIdentifier.id;
    }

    const newId = Math.max(...identifiers.map(i => i.id)) + 1;
    const newIdentifier = {
      id: newId,
      name: 'New Category',
      tags: [],
    };
    this.updatePortal({ identifiers: [...identifiers, newIdentifier] });
    return newId;
  }

  deleteCategory(id: number): Observable<number> {
    if (!this.portalId) {
      throw new Error('Portal ID is not set');
    }

    const deleteCategoryCallable = httpsCallable<{ portalId: string | null; categoryId: number; }, number>(this.functions, 'deleteIdentifierCategory');
    return from(deleteCategoryCallable({ portalId: this.portalId, categoryId: id })).pipe(
      take(1),
      map(result => result.data),
      tap(() => this.notif.openDismissibleSnackbar('Category deleted successfully')),
      catchError((err: FirebaseError) => {
        this.notif.openDismissibleSnackbar('Error deleting category: ' + err.message);
        throw err;
      })
    );
  }

  updateIdentifierName(id: number, name: string) {
    const identifiers = this.portal.value?.identifiers;

    if (!identifiers) {
      this.notif.openDismissibleSnackbar('Unable to update category name');
      return;
    }

    const identifier = identifiers.find(i => i.id === id);
    if (identifier) {
      if (identifier.name !== name) {
        identifier.name = name;
        this.updatePortal({ identifiers });
      }
    }
  }

  addTag(id: number, tag: string) {
    const identifiers = this.portal.value?.identifiers;

    if (!identifiers) {
      this.notif.openDismissibleSnackbar('Unable to add identifier');
      return;
    }

    const identifier = identifiers.find(i => i.id === id);
    if (identifier) {
      // No need to add the same tag
      if (identifier.tags.includes(tag)) {
        return;
      }
      identifier.tags.push(tag);
      this.updatePortal({ identifiers });
    }
  }

  removeTag(id: number, tag: string): void {
    if (!this.portalId) {
      throw new Error('Portal ID is not set');
    }

    const deleteIdentifierCallable = httpsCallable<{ portalId: string | null; categoryId: number; identifier: string; }>(this.functions, 'deleteIdentifier');
    from(deleteIdentifierCallable({ portalId: this.portalId, categoryId: id, identifier: tag })).pipe(
      take(1),
      tap(() => this.notif.openDismissibleSnackbar(`${tag} deleted`)),
      catchError((err: FirebaseError) => {
        this.notif.openDismissibleSnackbar(`Error deleting ${tag}: ${err.message}`);
        throw err;
      })
    ).subscribe();
  }

  public async submitNPSSurvey(score: number, comments: string) {
    const user = await this.as.currentUser();

    if (user) {
      const surveyUid = doc(collection(this.firestore, NPS_SURVEY_RESULTS)).id;

      const survey: NpsSurvey = {
        satisfaction: score,
        comments: comments,
        version: 1,
        fullName: user.name,
        email: user.email,
        crmAccountId: this.portal.value?.crmAccountId || '',
        portalName: this.portal.value?.name || '',
        portalId: this.portal.value?.id || '',
        title: user.admin ? 'Admin' : 'Counselor',
        completedDate: new Date(),
        uid: surveyUid,
        adminUserUid: user.uid,
        customerNumber: this.portal.value?.customerNumber || '',
        completedEvaluees: (this.statistics.value?.userCompleted || 0) + (this.statistics.value?.userArchived || 0)
      };

      try {
        await setDoc(doc(this.firestore, `${NPS_SURVEY_RESULTS}/${surveyUid}`), <NpsSurvey>survey);
        await this.as.updateAdminUser({
          lastNpsSurveyCompletedDate: new Date(),
          lastNpsSurveyCompletedId: surveyUid
        });

        this.notif.openDismissibleSnackbar('Thank you for your feedback');
      } catch (error) {
        console.error('Error submitting NPS survey: ', error);
        this.notif.openDismissibleSnackbar('Error submitting feedback');
      }
    }
  }
}
