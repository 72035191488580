import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable, combineLatest, from, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PortalService } from './portal.service';
import { convertTimestampsPipe } from '../pipes/convert-firestore-timestamp.pipe';
import { AptitudeScore, AptitudeTask, Assessment, Counselor, CounselorHistory, Evaluee, EvalueeDataUpdateForm, EvalueeDataUpdateTransfer, EvalueeStatusOptions, InterestScore, Register, User } from '@career-scope/models';
import { AuthService } from './auth.service';
import { collection, collectionData, CollectionReference, doc, docData, DocumentReference, Firestore, query, setDoc, updateDoc, where, writeBatch } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class EvalueeService {
  evaluee = new BehaviorSubject<Evaluee | null | undefined>(null);
  evaluee$: Observable<Evaluee | undefined> = of(undefined);
  evalueeSubscription: Subscription | null = null;

  assessment = new BehaviorSubject<Assessment | null | undefined>(null);
  assessment$: Observable<Assessment | undefined> = of(undefined);
  assessmentSubscription: Subscription | null = null;

  constructor(
    private firestore: Firestore,
    private as: AuthService,
    private ps: PortalService,
    private functions: Functions,
    private notif: NotificationService
  ) {
    this.as.user$.pipe(
      tap(user => {
        if (user) {
          return;
        }

        this.evaluee$ = of(undefined);
        this.assessment$ = of(undefined);
        this.evalueeSubscription?.unsubscribe();
        this.assessmentSubscription?.unsubscribe();
        this.evaluee.next(null);
        this.assessment.next(null);
      })
    ).subscribe();
  }

  setEvalueeObservable(portalId: string, uid: string) {
    const evaluee = this.evaluee.value;
    if (evaluee?.portalId === portalId && evaluee?.uid === uid) {
      return;
    }

    this.evaluee.next(null);
    this.evalueeSubscription?.unsubscribe();

    const evalueeDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${uid}`) as DocumentReference<Evaluee>;
    this.evaluee$ = docData(evalueeDocRef).pipe(
      convertTimestampsPipe(),
      tap((evaluee) => {
        if (!evaluee) {
          this.evaluee.next(undefined);
          return;
        }

        // TODO: Look at updating this when we save the grade to Firestore
        const adjustedGrade = this.calculateGrade(evaluee);
        this.evaluee.next({ ...evaluee, adjustedGrade });
        this.setAssessmentObservable(portalId, uid, evaluee.currentAssessmentId);
      })
    );

    this.evalueeSubscription = this.evaluee$.subscribe();
  }

  setAssessmentObservable(portalId: string, evalueeId: string, assessmentId: string) {
    const assessment = this.assessment.value;
    if (assessment?.portalId === portalId && assessment?.evalueeId === evalueeId && assessment?.id === assessmentId) {
      return;
    }

    this.assessment.next(null);
    this.assessmentSubscription?.unsubscribe();

    const assessmentDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}/assessments/${assessmentId}`) as DocumentReference<Assessment>;
    this.assessment$ = docData(assessmentDocRef).pipe(
      // Assuming convertTimestampsPipe is a custom operator to convert timestamps
      convertTimestampsPipe()
    );

    this.assessmentSubscription = this.assessment$.subscribe(res => {
      if (res) {
        this.assessment.next(res);
      }
    });
  }

  // This creates a subset of the interest scores that are ranked in the top 3 (including ties)
  // The top interests are used to filter occupations to align with high interest
  topInterestScores(interestScores: InterestScore[]): InterestScore[] {
    const scores = interestScores?.filter(interest => interest.rank > 0);
    scores?.sort((a, b) => a.rank - b.rank);
    return scores;
  }

  closeOut() {
    this.evaluee.next(null);
    this.evalueeSubscription?.unsubscribe();
  }

  updateEvaluee(evalueeUpdates: Partial<Evaluee>) {
    const evaluee = this.evaluee.value;
    if (evaluee) {
      const portalId = this.getPortalId(evaluee);
      const uid = this.getUid(evaluee);
      const updatedEvaluee = { ...evaluee, ...evalueeUpdates };
      updatedEvaluee.lastModifiedDate = new Date(Date.now());

      const evalueeDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${uid}`) as DocumentReference<Evaluee>;
      setDoc(evalueeDocRef, <Partial<Evaluee>>updatedEvaluee, { merge: true });
    }
  }

  updateAssessment(assessmentUpdates: Partial<Assessment>) {
    const assessment = this.assessment.value;
    if (!assessment) {
      return;
    }
    const updatedAssessment = { ...assessment, ...assessmentUpdates };

    const assessmentDocRef = doc(this.firestore, `portals/${assessment.portalId}/evaluees/${assessment.evalueeId}/assessments/${assessment.id}`) as DocumentReference<Assessment>;
    setDoc(assessmentDocRef, <Partial<Assessment>>updatedAssessment, { merge: true });
  }

  resetExercise(assessment: Assessment, task: AptitudeTask) {
    // Evaluee properties
    const exerciseStatus = 'Exercise ' + task.name + ' reset';
    const lastActivity = 'Exercise ' + task.name + ' reset';
    const lastActivityDate = new Date(Date.now());
    const lastModifiedDate = new Date(Date.now());
    const status = 'in progress';

    // Assessment properties
    // Remove all evalueeAnswers for the task ID
    const updatedAnswers = assessment.evalueeAnswers.filter(answer => answer.taskId !== task.id);

    // Remove item from taskResults associated with task ID
    const taskResults = assessment.taskResults.filter(result => result.taskId !== task.id);
    const exercisesComplete = null;
    const aptitudeScores: AptitudeScore[] = [];

    this.updateEvaluee({ exerciseStatus, status, lastActivity, lastActivityDate, lastModifiedDate });
    this.updateAssessment({ aptitudeScores, exercisesComplete, taskResults, evalueeAnswers: updatedAnswers });
  }

  deleteEvaluee(evaluee: Evaluee) {
    this.deleteEvalueeFromFirestore(String(evaluee.uid || evaluee.sqlId), evaluee.userId || '', evaluee?.registrationId || null, evaluee.portalId, evaluee.status);
  }

  deleteEvalueeFromFirestore(uid: string | undefined, userId: string, registrationId: string | null | undefined, portalId: string, status: EvalueeStatusOptions) {
    if (!uid) {
      console.error('UID is undefined');
      return;
    }

    const batch = writeBatch(this.firestore);

    // Remove evaluee from portals/portal/Evaluee
    const evalueeDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${uid}`);
    batch.delete(evalueeDocRef);

    // All users should have a registrationId. 
    // If they do not then they were imported and never registered or created via self registration
    if (registrationId) {
      const registrationDocRef = doc(this.firestore, `registrations/${registrationId}`);
      batch.delete(registrationDocRef);
    }

    // Remove user from users
    if (userId) {
      const userDocRef = doc(this.firestore, `users/${userId}`);
      batch.delete(userDocRef);
      // Cloud function deletes corresponding auth record for user
    }

    // Commit the batch
    batch.commit()
      .then(() => {
        if (status === 'in progress' || status === 'new') {
          this.ps.returnAdministration();
        }
        this.notif.openDismissibleSnackbar('Assessment Cancelled!');
      })
      .catch(error => {
        console.error('Error deleting evaluee: ', uid, ' from portal: ', portalId, error);
      });
  }


  updateEvalueeCounselor(counselor: Counselor, evaluee: Evaluee) {
    const currentCounselor: Counselor = evaluee.counselor;
    const counselorHistory: CounselorHistory[] = evaluee.counselorHistory || [];
    this.updateEvaluee(
      {
        counselor,
        counselorHistory: [{ counselor: currentCounselor, endDate: new Date(Date.now()) }, ...counselorHistory],
      }
    );
  }

  archiveEvaluee() {
    this.updateEvaluee({ status: 'archived' });
  }

  unarchiveEvaluee() {
    this.updateEvaluee({ status: 'completed' });
  }

  viewMyPathAccess(evaluee: Evaluee, access: boolean) {
    this.updateEvaluee({ assessmentSettings: { ...evaluee.assessmentSettings, viewMyPath: access } });
  }

  private getPortalId(evaluee: Evaluee): string {
    return evaluee.portalId;
  }

  private getUid(evaluee: Evaluee): string {
    return evaluee.uid || String(evaluee.sqlId);
  }


  private calculateGrade(evaluee: Evaluee): number | null {

    let grade = evaluee.currentGrade ? Number(String(evaluee.currentGrade).replace(/[^0-9]/g, '')) : null; // Currently stored as a string

    // If no grade but younger than 17, set a grade level.
    if (!grade) {
      if (evaluee.age) {
        grade = evaluee.age <= 14 ? 8 : evaluee.age === 15 ? 9 : evaluee.age === 16 ? 10 : null;
      }
    }
    if (grade) {
      grade = grade < 8 ? 8 : grade; // Anything lower than 8th grade is considered 8th gread
      grade = grade > 10 ? null : grade; // Anything higher than 10th grade is considered an adult
    }
    return grade;
  }

  // Logic for this is also in apps/career-scope auth.service
  checkForExistingEmail(email: string): Observable<boolean> {
    const registrationsCollectionRef = collection(this.firestore, 'registrations') as CollectionReference<Register>;
    const usersCollectionRef = collection(this.firestore, 'users') as CollectionReference<User>;

    const registrationsQuery = query(registrationsCollectionRef, where('email', '==', email));
    const usersQuery = query(usersCollectionRef, where('email', '==', email));

    const registrations$ = collectionData<Register>(registrationsQuery);
    const users$ = collectionData<User>(usersQuery);

    return combineLatest([registrations$, users$]).pipe(
      map(([registrations, users]) => {
        return !!registrations.length || !!users.length;
      })
    );
  }

  checkForExistingUsername(username: string): Observable<boolean> {
    const usersCollectionRef = collection(this.firestore, 'users') as CollectionReference<User>;
    const usersQuery = query(usersCollectionRef, where('username', '==', username.toLowerCase()));

    const users$ = collectionData<User>(usersQuery);

    return users$.pipe(
      map(users => {
        return !!users.length;
      })
    );
  }

  calculateResults() {
    this.updateAssessment({ aptitudeScores: [], interestResults: [], topInterestScores: [], interestScores: [] });
  }

  updateEvalueeData(evalueeDataUpdate: EvalueeDataUpdateForm) {

    const dataToUpdate: EvalueeDataUpdateForm = {};

    return this.evaluee.pipe(
      take(1),
      switchMap(evaluee => {
        if (evaluee) {

          if (evalueeDataUpdate.fullName != evaluee.fullName && evalueeDataUpdate.fullName) {
            dataToUpdate.fullName = evalueeDataUpdate.fullName;
            dataToUpdate.lowerCaseFullName = evalueeDataUpdate.fullName?.toLowerCase();
          }

          if (evalueeDataUpdate.logonName != evaluee.logonName) {
            dataToUpdate.logonName = evalueeDataUpdate.logonName;
          }

          if (evalueeDataUpdate.secret) {
            dataToUpdate.secret = evalueeDataUpdate.secret;
          }

          if (evalueeDataUpdate.age != evaluee.age) {
            dataToUpdate.age = evalueeDataUpdate.age;
          }

          if (evalueeDataUpdate.counselor && evalueeDataUpdate.counselor?.id != evaluee.counselor.id) {
            dataToUpdate.counselor = evalueeDataUpdate.counselor;
            const counselorHistory = evaluee.counselorHistory || [];
            counselorHistory.push({
              counselor: evaluee.counselor,
              endDate: new Date(Date.now())
            });
            dataToUpdate.counselorHistory = counselorHistory;
          }

          const newData = JSON.stringify(evalueeDataUpdate.identifiers?.map(o => ({ id: o.id, identifier: o.identifier })).sort((a, b) => a.id - b.id));
          const current = JSON.stringify(evaluee.identifiers?.map(o => ({ id: o.id, identifier: o.identifier })).sort((a, b) => a.id - b.id));

          if (newData !== current) {
            dataToUpdate.identifiers = evalueeDataUpdate.identifiers;
            dataToUpdate.identifierCategories = evalueeDataUpdate.identifiers?.map(identifier => identifier.id) || [];
          }

          if (Object.keys(dataToUpdate).length === 0) {
            throw 'No changes made';
          }

          if (dataToUpdate.logonName || dataToUpdate.secret) {
            const portalId = this.getPortalId(evaluee);
            const uid = this.getUid(evaluee);

            const evalueeDataUpdateTransfer: EvalueeDataUpdateTransfer = {
              portalId,
              uid,
              data: dataToUpdate
            };

            if (dataToUpdate.logonName) {
              if (!evaluee.registeredWithUsername) {
                return this.checkForExistingEmail(dataToUpdate.logonName).pipe(
                  take(1),
                  switchMap((exists) => {
                    if (exists) {
                      throw new Error('Email already exists');
                    }

                    return this.updateEvalueeDataTransfer(evalueeDataUpdateTransfer).pipe(take(1));
                  })
                );
              }

              return this.checkForExistingUsername(dataToUpdate.logonName).pipe(
                take(1),
                switchMap((exists) => {
                  if (exists) {
                    throw new Error('Username already exists');
                  }

                  return this.updateEvalueeDataTransfer(evalueeDataUpdateTransfer).pipe(take(1));
                })
              );
            }


            return this.updateEvalueeDataTransfer(evalueeDataUpdateTransfer).pipe(take(1));
          }

          return this.updateEvalueeAsObservable(dataToUpdate);
        }

        throw 'Evaluee not found';
      })
    );
  }

  updateEvalueeDataTransfer(evalueeDataUpdateTransfer: EvalueeDataUpdateTransfer): Observable<string> {
    const updateEvalueeDataCallable = httpsCallable<EvalueeDataUpdateTransfer, string>(this.functions, 'updateEvalueeData');
    return from(updateEvalueeDataCallable(evalueeDataUpdateTransfer)).pipe(
      take(1),
      map(result => result.data)
    );
  }

  updateEvalueeAsObservable(evalueeUpdates: Partial<Evaluee>): Observable<string> {
    return this.evaluee.pipe(
      take(1),
      switchMap(evaluee => {
        if (evaluee) {
          const portalId = this.getPortalId(evaluee);
          const uid = this.getUid(evaluee);
          evalueeUpdates = { ...evalueeUpdates, lastModifiedDate: new Date() };

          const evalueeDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${uid}`);
          return from(updateDoc(evalueeDocRef, evalueeUpdates)).pipe(
            map(() => uid)
          );
        } else {
          throw new Error('Evaluee not found');
        }
      })
    );
  }
}
